import _, {
  noop, partial, isEqual, isEmpty,
} from 'underscore';
import { loggedIn } from 'bv-helpers/session';
import { appendParamsToUrl } from './helpers/url';
import { pushDatalayer } from './helpers/data_layer/datalayer_push';
import stringToHash from './helpers/string_to_hash';

const { reduxState } = window;
const pageLoadVersion = window.BVVars.version;
const csrfTokenElement = document.head.querySelector('[name=csrf-token][content]');
const captchaStringElement = document.head.querySelector('[name=captcha-string][content]');
const getCSRFToken = () => csrfTokenElement && csrfTokenElement.content;
const customCaptchaToken = () => {
  const now = Math.round(Date.now() / 1000) + (window.VCSTATE?.TIME_DRIFT || 0);
  return {
    'x-ct': stringToHash(captchaStringElement ? captchaStringElement.content : '') + now,
    'x-ct-d': now,
  };
};

// =========
// Fetch API
// =========

class ApiError extends Error {
  constructor(response, responseJSON) {
    super(response.statusText);

    this.status = response.status;
    if (responseJSON) this.body = responseJSON;
    if (window.Error.captureStackTrace) window.Error.captureStackTrace(this, ApiError);
  }
}

const parseJSON = (response) => {
  const { headers } = response;

  if (headers.get('x-reload') === 'true') {
    window.setTimeout(() => { window.location.reload(); }, 10);
  }

  if (headers.get('x-maintenance')) {
    window.location.href = headers.get('x-maintenance');
  }

  if (headers.get('x-bv-version') !== pageLoadVersion) {
    reduxState?.store?.dispatch(reduxState?.store?.actionDispatchers?.app?.setVersion(headers.get('x-bv-version')));
  }

  const responseJSON = headers.has('content-length') && headers.get('content-length') === '0'
    ? null : response.json();

  if (!response.ok) {
    // TODO: Global error handling probably should be moved to a different module
    if (response.status === 422 && responseJSON) {
      responseJSON
        .then(({ csrfToken }) => {
          if (csrfToken) csrfTokenElement.content = csrfToken;
        })
        .catch(noop);
    }
    throw new ApiError(response, responseJSON);
  } else if (headers.get('x-csrf-token')) {
    csrfTokenElement.content = headers.get('x-csrf-token');
  }

  return responseJSON;
};

export const getJSON = (baseUrl, params = {}) => {
  const { fetchOpts, ...queryParams } = params;
  const { location: { origin } } = window;
  const url = new URL(baseUrl.toString(), origin);

  pushDatalayer(
    'GET',
    url.pathname,
    'request',
    { request: queryParams },
  );

  return fetch(
    appendParamsToUrl(url, queryParams),
    {
      ...fetchOpts,
      headers: {
        Accept: 'application/json',
        ...(url.origin === origin && {
          'X-CSRF-Token': getCSRFToken(),
          ...customCaptchaToken(),
        }),
      },
      credentials: 'same-origin',
    },
  ).then(parseJSON).then((data) => {
    pushDatalayer(
      'GET',
      url.pathname,
      'response',
      { request: queryParams, response: data },
    );
    return data;
  });
};

export const post = (
  url, params, jsonBody = true, headers = {}, replaceHeaders = {},
) => {
  pushDatalayer(
    'POST',
    url,
    'request',
    { request: params },
  );
  return fetch(url, {
    method: 'POST',
    headers: !isEmpty(replaceHeaders) ? replaceHeaders : {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': jsonBody ? 'application/json' : 'application/x-www-form-urlencoded',
      'X-CSRF-Token': getCSRFToken(),
      ...customCaptchaToken(),
      ...headers,
    },
    body: jsonBody ? JSON.stringify(params) : params,
    credentials: 'same-origin',
  }).then(parseJSON).then((data) => {
    pushDatalayer(
      'POST',
      url,
      'response',
      { request: params, response: data },
    );
    return data;
  });
};

export const putJSON = (url, params) => {
  pushDatalayer(
    'PUT',
    url,
    'request',
    { request: params },
  );

  return fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
      ...customCaptchaToken(),
    },
    body: JSON.stringify(params),
    credentials: 'same-origin',
  }).then(parseJSON).then((data) => {
    pushDatalayer(
      'PUT',
      url,
      'response',
      { request: params, response: data },
    );
    return data;
  });
};

export const putFormJSON = (url, params) => new Promise((resolve, reject) => {
  const xhr = new XMLHttpRequest();
  const body = new URLSearchParams(params);

  pushDatalayer(
    'PUT',
    url,
    'request',
    { request: params },
  );
  xhr.open('PUT', url, true);
  xhr.setRequestHeader('Accept', 'application/json, text/plain, */*');
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  xhr.setRequestHeader('X-CSRF-Token', getCSRFToken());
  Object.entries(customCaptchaToken()).forEach(([k, v]) => {
    xhr.setRequestHeader(k, v);
  });

  xhr.onload = () => {
    pushDatalayer(
      'PUT',
      url,
      'response',
      { request: params, response: JSON.parse(xhr.responseText) },
    );
    if (xhr.readyState === 4 && xhr.status === 200) {
      resolve(JSON.parse(xhr.responseText));
    } else {
      reject();
    }
  };

  xhr.send(body);
});

export const deleteJSON = (url, params) => {
  pushDatalayer(
    'DELETE',
    url,
    'request',
    { request: params },
  );
  return fetch(url, {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCSRFToken(),
      ...customCaptchaToken(),
    },
    credentials: 'same-origin',
    body: JSON.stringify(params),
  }).then(parseJSON).then((data) => {
    pushDatalayer(
      'DELETE',
      url,
      'response',
      { request: params, response: data },
    );
    return data;
  });
};

const validEntry = (comparisonObject) => ({ expiresIn, ...rest }) => (
  Object.entries(comparisonObject).every(([key, value]) => isEqual(rest[key], value))
    && expiresIn > new Date().getTime() / 1000
);

const getPreloadedRequests = () => {
  const { PreloadedRequests = [] } = window;

  return PreloadedRequests;
};

const preloadFor = (url, params) => getPreloadedRequests().find(validEntry({ url, params }));

export const postJSON = partial(post, _, _, true, _);
export const postFormData = partial(post, _, _, false);

export const getJSONWithPreload = (...args) => {
  const preloadObject = preloadFor(...args);

  return preloadObject && !loggedIn() ? Promise.resolve(preloadObject.data) : getJSON(...args);
};

// TODO: Lot of duplication here + content type should not be linked to a get, post or put
// Compose functions similar to:
// compose(toJSON, doFetch, addPutMethod, commonHeaders);
// compose(toJSON, doFetch, addPostMethod, stringifyBody, addJSONContentType, commonHeaders)
// compose(toJSON, doFetch, addGetMethod, addJSONContentType, commonHeaders)

// TODO: As csrfToken is obtained from the meta, it should be read here always
// Instead of having to obtain it in many places and adding an argument here
