import { getJSON } from 'bv-fetch';
import { accountNumber } from 'bv-helpers/session';

let accountDataPromise;

const emptyResponse = () => ({});

const getAccountData = () => (
  getJSON('/bv_api/current_account', { l: accountNumber() }).catch(emptyResponse)
);

export default () => {
  accountDataPromise = accountDataPromise || getAccountData();

  return accountDataPromise;
};
